*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto",sans-serif;
    text-decoration: none;
    transition: 0.5s;
}
.sand_btn,
.add_btn{
    width: 134px;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #9B38DC;
    border: none;
    border-radius: 10px;
    margin: 15px;
}
.flex_buttons{
    display: flex;
    justify-content: flex-end;
}
.save_button,
.sand_btn{
    background-color:#F7F7F7 ;
    color: #9B38DC;
    cursor: pointer;
}
.add_btn:hover,
.save_button:hover,
.sand_btn:hover{
    scale: 115%;
    cursor: pointer;
}
.default{
    cursor: none;
}
.default:hover{
    scale: 100%;
}
.save_button{
    background-color: #9B38DC;
    color: #F8F8F8;
}
body{
    background-color:#FEFDFD ;
}
.closeIcon{
    position: absolute;
    right: 10px;
    top: 10px;
}
.changeINput{
    background-color:#F8F8F8;
}

.pink{
    background-color: #F4EAFA;
}

a{
    color: #8C8C8C;
}
.under_review{
    width: 25px;
    cursor: pointer;
}
.category_C {
    position: relative;
    list-style: none;
}

.category_ML {
    display: none;
    position: absolute;
    top: 0;
    left: 98%;
    background-color: #fff;
    border: 1px solid #ccc;
    list-style: none;
    padding: 0;
}
.category_ML > li{
    background-color:#F8F8F8;
    position: relative;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    padding: 16px 20px;
}
.category_ML > li:hover{
    background-color:#EBEBEB;
}
.category_C > li:hover .category_ML {
    display: block;
    display: none;
    position: absolute;
    top: 0;
    left: 198%;
    background-color: #fff;
    border: none;
    z-index: 500;
}
.loadingBody{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1% 46%;
}
.loading{
    width: 100%;
}
option{
    cursor:pointer;
}
.closeIcon{
    cursor: pointer;
}
.ladingBlock{
    width: 100%;
    display: flex;justify-content: center;align-items: center;
}
.ladingBlock > img  {
    width: 100px;
}
.loader{
    width: 100px;
}
textarea{
    resize: none;
}
.red_btn{
    background-color: red;
    color: white;
}
.rose_btn{
    background-color: #d157ff;
    color: white;
}
.green_btn{
    background-color: #74D215;
    color: white;
}
.orange_btn{
    background-color: #ffa400;
    color: white;
}
.chage_Btn{
    width: 100px;
}
.calendar-block .calendar {
    display: none;
}

.calendar-block:hover .calendar {
    display: block;
}
.spredBlock{
    /*display: flex;*/
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
    background-color: rgba(140, 140, 140, 0.39);
    position: absolute;
    bottom: -1px;
    right: 21px;
}
td{position: relative}