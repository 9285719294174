.container {
    height: 70svh;
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.text {
    font-size: 26px;
    line-height: 35px;
    text-align: center;
}

.link {
    text-decoration: underline;
    color: #9B38DC;
}